import { Link, navigate, Router } from '@reach/router';
import { observer } from 'mobx-react-lite';
import { onPatch } from 'mobx-state-tree';
import React, { useContext, useEffect } from 'react';
import * as api from '../../api';
import { CategoriesContext } from '../../contexts/Categories.js';
import { ChatsContext } from '../../contexts/Chats.js';
import { LearningAssessmentsContext } from '../../contexts/LearningAssessments';
import { ListenersContext } from '../../contexts/Listeners';
import { PrivacyPoliciesContext } from '../../contexts/PrivacyPolicies';
import { TopicsContext } from '../../contexts/Topics.js';
import { UsersContext } from '../../contexts/Users';
import firebase from '../../firebase/index.js';
import manchesterLogo from '../../svgs/mae_logo.png';
import rochdaleLogo from '../../svgs/rochdale-logo.png';
import {
  addParticipantsToNewGroupChat,
  updateGroupChats,
  updateIndividualChats,
} from '../../utils/store';
import Loading from '../common/Loading';
import * as CSS from './elements/Auth';
import LoginForm from './LoginForm';
import PrivacyPolicy from './PrivacyPolicy';
import RequestReset from './RequestReset';
import ResetPassword from './ResetPassword';
import ResetPin from './ResetPin';
const { REACT_APP_THEME = 'talkEnglish' } = process.env;

const Auth = observer(({ children }) => {
  const usersStore = useContext(UsersContext);
  const chatsStore = useContext(ChatsContext);
  const topicsStore = useContext(TopicsContext);
  const categoriesStore = useContext(CategoriesContext);
  const listenersStore = useContext(ListenersContext);
  const learningAssessmentsStore = useContext(LearningAssessmentsContext);
  const privacyPoliciesStore = useContext(PrivacyPoliciesContext);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      firebase
        .auth()
        .signInWithCustomToken(storedToken)
        .then(({ user }) => usersStore.setLoggedInUserId(user.uid))
        .catch((err) => localStorage.removeItem('token'));
    }
  }, [usersStore]);

  useEffect(() => {
    return () => {
      listenersStore.unsubscribeFromAll();
    };
  }, [listenersStore]);

  const userSignIn = ({ phoneNumber, pin }) => {
    return api
      .login({ phoneNumber, pin })
      .then((token) => {
        localStorage.setItem('token', token);
        return firebase.auth().signInWithCustomToken(token);
      })
      .then(({ user }) => usersStore.setLoggedInUserId(user.uid));
  };

  useEffect(() => {
    onPatch(
      chatsStore,
      addParticipantsToNewGroupChat({ chatsStore, usersStore })
    );
    onPatch(
      usersStore,
      updateIndividualChats({
        chatsStore,
        usersStore,
        listenersStore,
      })
    );
    onPatch(
      usersStore,
      updateGroupChats({ chatsStore, listenersStore, usersStore })
    );
  }, [chatsStore, listenersStore, usersStore]);

  useEffect(() => {
    if (usersStore.loggedInUserId) {
      usersStore.storeLoggedInUser().then((user) => {
        const isStaff = ['teacher', 'admin'].includes(user.role);
        const isAdmin = user.role === 'admin';

        const privateUnSub = user.listenToPrivate();
        listenersStore.addUnSub(privateUnSub);

        const categoriesUnsub = categoriesStore.listenToCategories();
        listenersStore.addUnSub(categoriesUnsub);

        if (isStaff) {
          const learningAssessmentsUnSub = learningAssessmentsStore.listenToUpdates();
          listenersStore.addUnSub(learningAssessmentsUnSub);
        }
        if (isAdmin) {
          // const groupChatUnSub = chatsStore.listenToAllGroupChats(
          //   user.selectedCategory
          // );
          // listenersStore.addCategoryUnSub(groupChatUnSub);
          const privacyPoliciesUnSub = privacyPoliciesStore.listenToAllPrivacyPolicies();
          listenersStore.addUnSub(privacyPoliciesUnSub);
        }
      });
    }
  }, [
    chatsStore,
    learningAssessmentsStore,
    listenersStore,
    privacyPoliciesStore,
    topicsStore,
    usersStore,
    usersStore.loggedInUserId,
    categoriesStore,
  ]);

  const loggedInUser = usersStore.getLoggedInUser();
  const selectedCategory = loggedInUser?.selectedCategory;

  useEffect(() => {
    if (selectedCategory) {
      const loggedInUser = usersStore.getLoggedInUser();
      listenersStore.unsubscribeFromCategoryListeners();

      usersStore.clearOtherUsers();
      topicsStore.clearTopics();
      // chatsStore.clearGroupChats();

      const isStaff = ['teacher', 'admin'].includes(loggedInUser.role);
      const isAdmin = loggedInUser.role === 'admin';
      const usersUnSub = usersStore.fetchUsers(
        isStaff,
        loggedInUser.selectedCategory,
        listenersStore
      );
      listenersStore.addCategoryUnSub(usersUnSub);

      const topicsUnsub = topicsStore.listenToTopics(
        loggedInUser.selectedCategory
      );
      listenersStore.addCategoryUnSub(topicsUnsub);
      if (isAdmin) {
        const groupChatUnSub = chatsStore.listenToAllGroupChats(
          loggedInUser.selectedCategory
        );
        listenersStore.addCategoryUnSub(groupChatUnSub);
      }
    }
  }, [
    listenersStore,
    usersStore,
    usersStore.loggedInUserId,
    loggedInUser,
    selectedCategory,
    topicsStore,
    chatsStore,
  ]);

  if (loggedInUser) {
    if (
      !loggedInUser?.private?.phoneNumber ||
      usersStore.loading ||
      usersStore.users.size < 1
    )
      return (
        <CSS.Fullscreen>
          <Loading />
        </CSS.Fullscreen>
      );
    else if (loggedInUser?.private?.resetRequired) {
      if (loggedInUser.role === 'student') {
        return <ResetPin resetRequired={true} />;
      } else {
        return <ResetPassword resetRequired={true} />;
      }
    } else return children;
  }

  const SignInFlow = observer(({ isStaff }) => {
    return (
      <CSS.AuthContainer>
        <CSS.FormWrapper>
          <LoginForm userSignIn={userSignIn} isStaff={isStaff} />
        </CSS.FormWrapper>
        <CSS.ResetButton
          narrow
          onClick={() => {
            navigate('/resetPin');
          }}
        >
          Reset PIN
        </CSS.ResetButton>
        {!isStaff && (
          <CSS.ResetButton
            narrow
            onClick={() => {
              navigate('/staff');
            }}
          >
            Staff Login
          </CSS.ResetButton>
        )}
        {REACT_APP_THEME === 'rochdale' ? (
          <CSS.PNGLogo src={rochdaleLogo} alt="talk English logo" />
        ) : REACT_APP_THEME === 'birminghamESOL' ? null : REACT_APP_THEME ===
          'birminghamAES' ? null : REACT_APP_THEME ===
          'bolton' ? null : REACT_APP_THEME ===
          'salford' ? null : REACT_APP_THEME ===
          'nottingham' ? null : REACT_APP_THEME ===
          'thurrock' ? null : REACT_APP_THEME === 'slace' ? null : (
          <CSS.PNGLogo src={manchesterLogo} alt="Adult Education Manchester" />
        )}
        <p>
          By using this website you signal that you have read and agreed to the{' '}
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </CSS.AuthContainer>
    );
  });

  return (
    <main>
      <Router primary={false}>
        <SignInFlow default />
        <SignInFlow path="/staff/*" isStaff />
        <RequestReset path="/resetPin" />
        <ResetPin path="/reset/:resetCode" />
        <ResetPassword path="/reset/:resetCode/staff" />
        <PrivacyPolicy path="/privacy" />
      </Router>
    </main>
  );
});

export default Auth;
